import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  HomeIcon,
  DocumentTextIcon,
  TrendingUpIcon,
  AcademicCapIcon,
  DatabaseIcon,
} from "@heroicons/react/outline";
import scrollreveal from "scrollreveal";
import { NavLink, useLocation } from "react-router-dom";
import { trailcoin } from "../images";
import { ethers } from "ethers";
import { ConnectWallet } from "@thirdweb-dev/react";

const navLinks = [
  { to: "/dashboard", label: "Dashboard", icon: <HomeIcon className="h-5 w-5 mr-2" /> },
 ];

const Sidebar = () => {
  const EXPLOITATION_FEES = 300;
  const CHAINLINK_PRICE_FEED_ADDRESS = '0x86896fEB19D8A607c3b11f2aF50A0f239Bd71CD0';

  // Chainlink Price Feed ABI
  const aggregatorV3InterfaceABI = [
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "description",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
      name: "getRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "latestRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "version",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ];

  const [goldPrice, setGoldPrice] = useState<number | null>(null);
  const [genPrice, setGenPrice] = useState<number | null>(null);
  const location = useLocation();
  const [navbarState, setNavbarState] = useState(false);

  useEffect(() => {
    const sr = scrollreveal({
      origin: "left",
      distance: "80px",
      duration: 1000,
      reset: false,
    });
    sr.reveal(".nav-link", { opacity: 0, interval: 300 });
  }, []);

  const toggleNavbar = () => setNavbarState(!navbarState);
  const closeNavbar = () => setNavbarState(false);

  const getPageName = () => {
    const pathSegments = location.pathname.split("/").filter((segment) => segment !== "");
    return pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : "Home";
  };

  const formatMoney = (value: number) => {
    return value?.toLocaleString("en-US", { style: "currency", currency: "USD" }) || "N/A";
  };

  useEffect(() => {
    const fetchGoldPrice = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
        const priceFeed = new ethers.Contract(CHAINLINK_PRICE_FEED_ADDRESS, aggregatorV3InterfaceABI, provider);
        const latestRoundData = await priceFeed.latestRoundData();
        const decimals = await priceFeed.decimals();
        const price = Number(latestRoundData.answer) / 10 ** decimals;
        setGoldPrice(price);
      } catch (error) {
        console.error('Error fetching gold price:', error);
      }
    };

    fetchGoldPrice();
  }, []);
  
  useEffect(() => {
    if (goldPrice !== null) {
      setGenPrice(goldPrice - EXPLOITATION_FEES);
    }
  }, [goldPrice]);

  return (
    <>
      <TopBar>
        <LogoSection>
          <img src={trailcoin} alt="Logo" />
          <p className="text-2xl sm:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-teal-500 hover:opacity-90 transition-opacity flex justify-between items-center gap-2">TRAILCOIN</p>
          </LogoSection>
        <TopBarTitle>
         <ConnectWallet
          btnTitle={"Login"}
         />
        </TopBarTitle>
      </TopBar>

      <MobileHeader>
        <HeaderContent>
          <SidebarHeader className="mt-5 mb-5 flex justify-center">
          <LogoSection>
          <img src={trailcoin} alt="Logo" />
          <p className="text-2xl sm:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-teal-500 hover:opacity-90 transition-opacity flex justify-between items-center gap-2">TRAIL</p>
          </LogoSection>
      </SidebarHeader>
          <div className="connectbtn">
          <ConnectWallet
          btnTitle={"Login"}
          style={{ color: "black", minWidth:"20px"}}

         />
          </div>
          <Hamburger onClick={toggleNavbar}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </Hamburger>
         
        </HeaderContent>
      </MobileHeader>

      <SidebarContainer className={navbarState ? "active" : ""}>
        <SidebarContent>
          <SidebarHeader>
            
          </SidebarHeader>
          <SidebarNav className="customTop">
            <ul>
              {navLinks.map((link) => (
                <li key={link.to}>
                  <StyledNavLink
                    to={link.to}
                    className={({ isActive }) => (isActive ? "active" : "")}
                    onClick={closeNavbar} // Close the sidebar when a link is clicked
                  >
                    {link.icon}
                    <span>{link.label}</span>
                  </StyledNavLink>
                </li>
              ))}
            </ul>
          </SidebarNav>
        </SidebarContent>
      </SidebarContainer>
      {navbarState && <Backdrop onClick={closeNavbar} />}
    </>
  );
};

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(48deg, #161b23, #b600ca);
  color: white;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 5px #00b3f0 solid;
  @media (max-width: 768px) {
  display: none;
  }
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  span {
    font-size: 1.2rem;
    font-weight: bold;
    color: #f1c40f;
  }
`;

const TopBarTitle = styled.div`
  text-align: right;
  text-transform:uppercase;
  h1 {
    font-size: 1.5rem;
    margin: 0;
    color: #f1c40f;
  }
  p {
    margin: 0;
    font-size: 0.9rem;
    color: #ecf0f1;
  }
`;

const MobileHeader = styled.div`
  display: none;
  width: 100%;
  background: #161e0f;
  color: #fff;
  text-transform:uppercase;
    padding: 0rem 1rem 0rem;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  border-bottom: 5px #00b3f0 solid;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Hamburger = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin-right: 0rem;

  svg {
    color: #fff;
  }
`;

const HeaderText = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Breadcrumbs = styled.div`
  display: flex;
  gap: 0.5rem;

  span {
    font-size: 0.875rem;
    color: #bdc3c7;
  }
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  color: #f1c40f;
`;

const SidebarContainer = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 250px;
  background: linear-gradient(182deg, #2566e8, #b600ca);
  color: #fff;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  border-right: 6px #00b3f0 solid;
  transform: translateX(-100%);
  z-index: 999; /* Ensure it's above the backdrop */
  &.active {
    transform: translateX(0);
  }

  @media (min-width: 768px) {
    transform: translateX(0);
  }
`;

const SidebarHeader = styled.div`
  // padding: 1rem;
  // background: #161e0f;
  // border-bottom: 1px solid #7f8c8d;
  display: flex;
  align-items: center;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    p {
      font-size: 0.9rem;
      font-weight: bold;
      margin: 0;
      color: #ecf0f1;
    }
    span {
      font-size: 0.8rem;
      color: #bdc3c7;
    }
  }
`;

const SidebarContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 3rem 14px;

`;

const SidebarNav = styled.nav`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin: 1rem 0;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  color: inherit;
  text-decoration: none;
  background-color: #191919;

  &.active {
    background-color: #1f2937;
    box-shadow: 0 0 7px #2566e8;
    color: #ffffff;
    font-size: 17px;
    font-weight: bolder;
    text-transform: uppercase;
  }

  &:hover {
    background-color: #d3a00c;
    box-shadow: 0 0 7px #3bca00;
    color: #272525;
    font-size: 17px;
    font-weight: bolder;
    text-transform: uppercase;
  }

  span {
    margin-left: 1rem;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 900;
`;

export default Sidebar;
