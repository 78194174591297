import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  ThirdwebProvider,
  ConnectWallet,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  localWallet,
  trustWallet,
} from "@thirdweb-dev/react";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Root from "./routes/root";

import Dashboard from "./pages/Dashboard";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from "./ErrorPage";
import BookLibrary from "./pages/Documents";
import MarketList from "./pages/MarketList";
import Academy from "./pages/Academy";
import DataPage from "./pages/CoinInfo";
import Homepage from "./pages/Homepage";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/documents",
        element: <BookLibrary />,
      },
      {
        path: "/markets",
        element: <MarketList />,
      },
      {
        path: "/academy",
        element: <Academy />,
      },
      {
        path: "/coin-info",
        element: <DataPage />,
      },

      // {
      //   path: "/smart-miner",
      //   element: <StakeNFT />,
      // },

      // {
      //   path: "/stake-trc",
      //   element: <StakeToken />,
      // },

      // {
      //   path: "/swap-trc",
      //   element: <FreshMint />,
      // },

      // {
      //   path: "/workshop",
      //   element: <Workshop />,
      // },

    ],
  },

]);



// This is the chain your dApp will work on.
// Change this to the chain your app is built for.
// You can also import additional chains from `@thirdweb-dev/chains` and pass them directly.
const activeChain = "binance";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
      <ThirdwebProvider
      activeChain={activeChain}
      clientId={process.env.REACT_APP_TEMPLATE_CLIENT_ID}
      supportedWallets={[
        metamaskWallet(),
        coinbaseWallet(),
        walletConnect(),
        localWallet(),
        trustWallet({ recommended: true }),
      ]}
    >
      <ToastContainer
position="bottom-left"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
<Analytics />
<SpeedInsights />
    <RouterProvider router={router} />
    </ThirdwebProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
