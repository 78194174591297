import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "@chakra-ui/react";
import Navbar from "../components/NavBar";
import PriceChart from "../components/pricechart";
import { ethers } from "ethers";
import { trailcoin } from "../images";
import InfoCard from "../components/InfoCard";
import { FaFacebookF, FaTwitter, FaTelegramPlane, FaCoins } from "react-icons/fa"; // Importing Icons
import { NavLink } from "react-router-dom";
import { Timeline } from 'react-twitter-widgets'

interface CoinInfo {
  address: string;
  chainId: number;
  priceUsd: number;
  priceUsd24hAgo: number;
  marketCapUsd: number;
  totalReserveUsd: number;
}

const Dashboard: React.FC = () => {
  const EXPLOITATION_FEES =0.90;
  const CHAINLINK_PRICE_FEED_ADDRESS = '0xB97Ad0E74fa7d920791E90258A6E2085088b4320';


  const [tasksCompleted, setTasksCompleted] = useState({
    telegramChannel: false,
    telegramGroup: false,
    followOnX: false,
    likeOnFacebook: false,
    followOnCMC: false,
  });

  const handleTaskClick = (taskName: string) => {
    setTasksCompleted((prevState) => ({
      ...prevState,
      [taskName]: true,
    }));
  };

  const allTasksCompleted = Object.values(tasksCompleted).every(Boolean);

  const aggregatorV3InterfaceABI = [
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "description",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
      name: "getRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "latestRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "version",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ];

  const [coinData, setCoinData] = useState<CoinInfo[]>([]);
  const [goldPrice, setGoldPrice] = useState<number | null>(null);
  const [genPrice, setGenPrice] = useState<number | null>(null);
  const [chartPriceHistory, setChartPriceHistory] = useState<{ date: string; price: number }[]>([]);

  useEffect(() => {
    async function fetchCoinData() {
      const url = "https://api.coinbrain.com/public/coin-info";
      const payload = {
        "56": ["0x0042e6b7db042cf384c60be5fa347de8a10dd9b3"], // Replace with actual token addresses
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);
        } else {
          console.error("Failed to fetch coin data.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }

    fetchCoinData();
  }, []);

  useEffect(() => {
    async function fetchGoldPrice() {
      try {
        const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
        const priceFeed = new ethers.Contract(CHAINLINK_PRICE_FEED_ADDRESS, aggregatorV3InterfaceABI, provider);
        const latestRoundData = await priceFeed.latestRoundData();
        const decimals = await priceFeed.decimals();
        const price = Number(latestRoundData.answer) / 10 ** decimals;
        setGoldPrice(price);
      } catch (error) {
        console.error('Error fetching gold price:', error);
      }
    }
    fetchGoldPrice();
  }, []);
  
  useEffect(() => {
    if (goldPrice !== null) {
      const genPriceCalculated = goldPrice - EXPLOITATION_FEES;
      setGenPrice(genPriceCalculated);
    }
  }, [goldPrice]);
  
  useEffect(() => {
    if (coinData.length > 0) {
      const currentPrice = coinData[0].priceUsd;
      const newChartData = [
        ...chartPriceHistory,
        { date: new Date().toLocaleDateString(), price: currentPrice },
      ];
      setChartPriceHistory(newChartData);
    }
  }, [coinData]);

  const formatMoney = (value: number) => {
    return value?.toLocaleString("en-US", { style: "currency", currency: "USD" }) || "N/A";
  };

  const priceChangePercentage = coinData.length > 0 && coinData[0].priceUsd24hAgo
    ? ((coinData[0].priceUsd - coinData[0].priceUsd24hAgo) / coinData[0].priceUsd24hAgo * 100).toFixed(2)
    : "0.00";

  const PriceComparison = () => {
    const genPriceFormatted = genPrice ? formatMoney(genPrice) : "$0.00";
    const currentPrice = coinData.length > 0 ? coinData[0].priceUsd : 0;
    const currentPriceFormatted = currentPrice ? formatMoney(currentPrice) : "$0.00";

    return (
      <div>
        <PriceStyled><b>{genPriceFormatted}</b></PriceStyled>
        {/* <PriceStyled><b>Initial Price:</b> {currentPriceFormatted}</PriceStyled> */}
        <PriceChange className={genPrice !== null && currentPrice > genPrice ? "positive" : "negative"}>
          {/* {genPrice !== null && currentPrice > genPrice
            ? `Initial price is higher than GEN price by ${formatMoney(currentPrice - genPrice)}`
            : genPrice !== null
            ? `Initial price is lower than GEN price by ${formatMoney(genPrice - currentPrice)}`
            : "GEN price data is not available"} */}
        </PriceChange>
      </div>
    );
  };

  return (
    <DashboardSection>
      <Navbar />
      <ContentGrid>
        <Card>
          <CardHeader>
            <LogoContainer>
              <img src={trailcoin} alt="Trailcoin Logo" />
              <div>
                <Title>Trailcoin (TRL)</Title>
                <AssetTags>
                  <Tag>Utility Token</Tag> 
                  <Tag>BSC</Tag> 
                  <Tag>Polygon zkEVM</Tag> 
                </AssetTags>
              </div>
            </LogoContainer>
            <PriceContainer>
              <PriceComparison />
              <PriceChange className={parseFloat(priceChangePercentage) >= 0 ? "positive" : "negative"}>
                {parseFloat(priceChangePercentage) >= 0 ? "+" : ""}{priceChangePercentage}%
              </PriceChange>
            </PriceContainer>
          </CardHeader>
          
          <section className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-4">
        <div className="bg-gradient-to-r from-gray-900 to-black rounded-lg shadow-xl text-white p-8 rounded-xl shadow-md lg:col-span-1">
       <center><img src={trailcoin} className="w-20 h-20" alt="Trailcoin Logo" /></center> 
          <p className="text-sm text-center  mt-4 mb-4">Stake TRAIL to earn rewards, participate in governance, and access exclusive benefits.</p>
          <h2 className="text-2xl font-semibold text-center mb-4 mt-4">Trailcoin Whitelist Tasks</h2>
          <ul className="menu bg-white w-full rounded-box">
<li>

<a className="text-black font-bold text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Follow Trail Network Channel
</a>

{tasksCompleted.telegramChannel ? (
 <span className="task-btn check-mark">✓</span>
) : (
 <a href="https://t.me/trailbuildersnetwork" target="_blank" className="task-btn" onClick={() => handleTaskClick('telegramChannel')}>GO</a>
)}

</li>
<li>
<a className="text-black font-bold text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Follow Trail Network Discussion</a>
{tasksCompleted.telegramGroup ? (
 <span className="task-btn check-mark">✓</span>
) : (
 <a href="https://t.me/trailbuilderscommunity" target="_blank" className="task-btn" onClick={() => handleTaskClick('telegramGroup')}>GO</a>
)}
</li>
<li>
<a className="text-black font-bold text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Follow Trail Network Facebook
</a>
{tasksCompleted.likeOnFacebook ? (
 <span className="task-btn check-mark">✓</span>
) : (
 <a href="https://web.facebook.com/trailsnetwork" target="_blank" className="task-btn" onClick={() => handleTaskClick('likeOnFacebook')}>GO</a>
)}
</li>
<li>
<a className="text-black font-bold text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Follow Trail Network on X
</a>
{tasksCompleted.followOnX ? (
 <span className=" task-btn check-mark">✓</span>
) : (
 <a href="https://x.com/trailsgamefi" target="_blank" className="task-btn" onClick={() => handleTaskClick('followOnX')}>GO</a>
)}
</li>
<li>
<a className="text-black font-bold text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Follow Trail on Coinmarketcap
</a>
{tasksCompleted.followOnCMC ? (
 <span className=" task-btn check-mark">✓</span>
) : (
 <a href="https://coinmarketcap.com/community/profile/frogspepe/" target="_blank" className="task-btn" onClick={() => handleTaskClick('followOnCMC')}>GO</a>
)}
</li>
</ul> 


           </div>


           <div className="bg-white p-8 rounded-xl shadow-md lg:col-span-2">
  <h2 className="text-2xl font-semibold text-gray-800 mb-4">Community Buzz</h2>
  <p className="text-black">As early adopters and contributors to the Trail Network, you'll have a unique opportunity to benefit from the project's growth. By actively participating in the community, you can accumulate Trail Coin and potentially see it grow in value exponentially.</p> 
  <div className="community-news mt-4">
    <Timeline 
            dataSource={{ sourceType: "profile", screenName: "trailsgamefi" }} // Or any other relevant Twitter handle
            options={{ height: "400", width: "100%" }} // Adjust the height as needed
          />   </div>
</div>
      </section>
        </Card>
      </ContentGrid>
    </DashboardSection>
  );
};



const DashboardSection = styled(Container)`
  margin-left: 12vw;
    padding: 2rem;
    color: #ffffff;
    margin-right: -1vw;

  @media (max-width: 768px) {
       margin-left: 0;
        padding: 0rem;
        margin-top: 27px;
  }
`;

const ContentGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const Card = styled.div`
  // background: #ffffff;
  border-radius: 8px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 8rem 5rem;
    margin-top: -58px;


  @media (max-width: 768px) {
 margin-left: 0;
        padding: 1rem;
        margin-top: -2px;  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;

    @media (max-width: 768px) {
      margin-right: 0.5rem;
    }
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const AssetTags = styled.div`
  margin-top: 0.3rem;
  display: flex;
  gap: 0.5rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Tag = styled.span`
      background: #bdc3c7;
    color: #1f2937;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const PriceContainer = styled.div`
  text-align: right;

  @media (max-width: 768px) {
    text-align: left;
    margin-top: 1.5rem;
;

  }
`;

const Price = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const PriceChange = styled.p`
  font-size: 1rem;

  &.positive {
    color: #3bca00;
  }

  &.negative {
    color: #000;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;


const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 1.25rem;
  transition: color 0.3s;

  &:hover {
    color: #10b981;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;


const PriceStyled = styled.p`
  font-size: 2rem; // Increase the font size
  font-weight: bold;
  margin: 0;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;



const ContractAddress = styled.p`
  font-size: 1rem;
  color: #4b5563;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const PriceChartContainer = styled.div`
  height: 202px; /* Set a fixed height or adjust as needed */
  margin-top: 2rem;

  @media (max-width: 768px) {
    height: 200px; /* Adjust for smaller screens */
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`;
export default Dashboard;


